export enum TimeSheetStatus {
  Draft,
  PendingApproval,
  Rejected,
  PendingReapproval,
  Approved,
  UnderReview,
  Exported
}

export const TimeSheetStatusDisplay = ["Draft", "Pending Approval", "Rejected", "Pending Reapproval", "Approved", "Under Review", "Exported"];