<div class="lg:w-1/5 md:w-1/2 w-full mr-4 mt-8">
  <app-text-input
    [label]="'Search'"
    [placeholder]="'Search'"
    [isSearch]="true"
    [formControl]="search" />
</div>

<div class="mt-8 flex flex-wrap">
  @if (selectedFilters.has('stateId')) {
    <app-dropdown
      class="mr-4"
      [placeholder]="'All States'"
      [search]="true"
      [label]="'State'"
      (onchange)="handleFiltersChange($event, 'stateId')"
      [defaultOption]="{ text: 'All States', value: '' }"
      [options]="states$ | async" />
  }

  @if (selectedFilters.has('city')) {
    <app-dropdown
      class="mr-4"
      [placeholder]="'All Cities'"
      [search]="true"
      [label]="'City'"
      (onchange)="handleFiltersChange($event, 'city')"
      [defaultOption]="{ text: 'All Cities', value: '' }"
      [options]="cities$ | async" />
  }

  @if (selectedFilters.has('PortId')) {
    <app-dropdown
      (onchange)="handleFiltersChange($event, 'PortId')"
      class="mr-4"
      [placeholder]="'All Ports'"
      [search]="true"
      [label]="'Ports'"
      [defaultOption]="{ text: 'All Ports', value: '' }"
      [options]="ports$ | async" />
  }

  @if (selectedFilters.has('TerminalId')) {
    <app-dropdown
      (onchange)="handleFiltersChange($event, 'TerminalId')"
      class="mr-4"
      [placeholder]="'All Terminals'"
      [search]="true"
      [label]="'Terminal'"
      [defaultOption]="{ text: 'All Terminals', value: '' }"
      [options]="(terminals$ | async).length == 0 ? (allTerminals$ | async) : (terminals$ | async)" />
  }

  @if (selectedFilters.has('FacilityType')) {
    <app-dropdown
      (onchange)="handleFiltersChange($event, 'FacilityType')"
      class="mr-4"
      [placeholder]="'All Facilities'"
      [search]="true"
      [label]="'Facility Type'"
      [defaultOption]="{ text: 'All Facilities', value: '' }"
      [options]="facilities$ | async" />
  }

  @if (selectedFilters.has('EmployerId')) {
    <app-dropdown
      (onchange)="handleFiltersChange($event, 'EmployerId')"
      class="mr-4"
      [placeholder]="'All Employers'"
      [search]="true"
      [label]="'Employer'"
      [defaultOption]="{ text: 'All Employers', value: '' }"
      [options]="(employers$ | async).length == 0 ? (allEmployers$ | async) : (employers$ | async)" />
  }

  @if (selectedFilters.has('UnionId')) {
    <app-dropdown
      class="mr-4"
      [placeholder]="'All Unions'"
      (onchange)="handleFiltersChange($event, 'UnionId')"
      [search]="true"
      [label]="'Union'"
      [defaultOption]="{ text: 'All Unions', value: '' }"
      [options]="unions$ | async" />
  }

  @if (selectedFilters.has('VesselId')) {
    <app-dropdown
      (onchange)="handleFiltersChange($event, 'VesselId')"
      class="mr-4"
      [placeholder]="'All Vessels'"
      [search]="true"
      [label]="'Vessel'"
      [defaultOption]="{ text: 'All Vessels', value: '' }"
      [options]="(vessels$ | async).length == 0 ? (allVessels$ | async) : (vessels$ | async)" />
  }

  @if (selectedFilters.has('TimeKeeperName')) {
    <app-dropdown
      (onchange)="handleFiltersChange($event, 'TimeKeeperName')"
      class="mr-4"
      [placeholder]="'All Timekeeper'"
      [search]="true"
      [label]="'Timekeeper'"
      [defaultOption]="{ text: 'All Timekeepers', value: '' }"
      [options]="filteredTimekeepers$ | async" />
  }

  @if (selectedFilters.has('LaborOrderState')) {
    <app-dropdown
      class="mr-4"
      [placeholder]="'All Statuses'"
      (onchange)="handleFiltersChange($event, 'LaborOrderState')"
      [search]="true"
      [label]="'Status'"
      [defaultOption]="{ text: 'All Statuses', value: '' }"
      [options]="LaborOrderStates" />
  }

  @if (selectedFilters.has('StatusId')) {
    <app-dropdown
      class="mr-4"
      [placeholder]="'All Statuses'"
      (onchange)="handleFiltersChange($event, 'StatusId')"
      [search]="true"
      [label]="'Status'"
      [defaultOption]="{ text: 'All Statuses', value: '' }"
      [options]="timeSheetStates" />
  }

  @if (selectedFilters.has('Worked')) {
    <app-dropdown
      class="mr-4"
      [placeholder]="'Status'"
      [search]="true"
      [label]="'Status'"
      [formControl]="workedForm"
      [options]="timeSheetStatesWorked" />
  }

  @if (selectedFilters.has('isActive')) {
    <app-dropdown
      class="mr-4"
      [placeholder]="'All Statuses'"
      (onchange)="handleFiltersChange($event, 'isActive')"
      [search]="true"
      [label]="'Status'"
      [defaultOption]="{ text: 'All Statuses', value: '' }"
      [options]="Statuses" />
  }

  @if (selectedFilters.has('FromDate')) {
    <app-date-picker
      class="mr-4"
      (onchange)="handleFiltersChange($event, 'FromDate')"
      [label]="'From Date'"
      [placeholder]="'All Dates'"
      [fullWidth]="false" />
  }
  
  @if (selectedFilters.has('ToDate')) {
    <app-date-picker
      class="mr-4"
      (onchange)="handleFiltersChange($event, 'ToDate')"
      [label]="'To Date'"
      [placeholder]="'All Dates'" />
  }
  
  @if (selectedFilters.has('StartDate')) {
    <app-date-picker
      class="mr-4"
      (onchange)="handleFiltersChange($event, 'StartDate')"
      [label]="'Start Date'"
      [placeholder]="'All Dates'"
      [fullWidth]="false" />
  }
  
  @if (selectedFilters.has('StartTime')) {
    <app-time-input
      [label]="'Start Time'"
      [placeholder]="'Start time'"
      class="mr-4"
      [formControl]="startTime" />
  }

  @if (activeFilters.hasEnabledFilters) {
    <a (click)="handleResetButton()" class="self-end pb-2">
      reset filters
    </a>
  }
</div>
