import { AsyncPipe } from "@angular/common";
import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChildren
} from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { Select, Store } from "@ngxs/store";
import { Observable, Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";
import { UsersState } from "src/app/features/users/state/users.state";
import { SelectOption } from "../../interfaces/select-option.interface";
import { DropdownOptionsService } from "../../services/dropdown-options.service";
import { TimeSheetStatesWorked, objectHasValues } from "../../utils/utils";
import { DatePickerComponent } from '../date-picker/date-picker.component';
import { DropdownComponent } from "../dropdown/dropdown.component";
import { TextInputComponent } from "../text-input/text-input.component";
import { TimeInputComponent } from "../time-input/time-input.component";

export const availableFilters = {
	state: "stateId",
	city: "city",
	status: "isActive",
	facility: "FacilityType",
	terminal: "TerminalId",
	port: "PortId",
	employer: "EmployerId",
	laborOrderState: "LaborOrderState",
	fromDate: "FromDate",
	toDate: "ToDate",
	union: "UnionId",
	vessel: "VesselId",
	timekeeper: "TimeKeeperName",
	timeSheetStatus: "StatusId",
	startDate: "StartDate",
	startTime: "StartTime",
	timeSheetStatusWorked: "Worked",
	deletedTimesheets: "DeletedTimesheets"
};

interface ActiveFiltersType {
	hasEnabledFilters: boolean;
	items: object;
}

export class FilterAction {
	public static readonly type = "[Filters] filter";
}

@Component({
	selector: "app-filters",
	standalone: true,
	imports: [AsyncPipe, DropdownComponent, TextInputComponent, TimeInputComponent, DatePickerComponent],
	templateUrl: "./filters.component.html",
	styles: [
		`
         :host {
           width: 100%;
           display: flex;
           flex-wrap: wrap;
           align-items: flex-end;
         }
       `
	]
})
export class FiltersComponent implements OnInit, AfterViewInit, OnDestroy {
	activeFilters: ActiveFiltersType = { hasEnabledFilters: false, items: {} };
	selectedFilters: Set<string> = new Set<string>();
	Statuses: Array<SelectOption>;
	LaborOrderStates: Array<SelectOption>;
	timeSheetStates: Array<SelectOption>;
	timeSheetStatesWorked = TimeSheetStatesWorked;
	search = new UntypedFormControl("");
	startTime= new UntypedFormControl('');
	workedForm = new UntypedFormControl(true);

	@Input() useFilters: Array<string>;
	@Output() filterAction: EventEmitter<any> = new EventEmitter<any>();

	@ViewChildren(DropdownComponent) dropdowns: QueryList<DropdownComponent>;
	@ViewChildren(DatePickerComponent) datePickers: QueryList<DatePickerComponent>;

	unsubscribe$: Subject<void> = new Subject();

	@Select(state => state.prefillData.filteredStates) states$: Observable<Array<SelectOption>>;
	@Select(state => state.prefillData.facilities) facilities$: Observable<Array<SelectOption>>;
	@Select(state => state.prefillData.cities) cities$: Observable<Array<SelectOption>>;
	@Select(state => state.prefillData.filteredPorts) ports$: Observable<Array<SelectOption>>;
	@Select(state => state.prefillData.filteredTerminals) terminals$: Observable<Array<SelectOption>>;
	@Select(state => state.prefillData.allTerminals) allTerminals$: Observable<Array<SelectOption>>;
	@Select(state => state.prefillData.filteredEmployers) employers$: Observable<Array<SelectOption>>;
	@Select(state => state.prefillData.allEmployers) allEmployers$: Observable<Array<SelectOption>>;
	@Select(state => state.prefillData.allUnions) unions$: Observable<Array<SelectOption>>;
	@Select(state => state.prefillData.allVessels) vessels$: Observable<Array<SelectOption>>;
	@Select(state => state.prefillData.filteredVessels) allVessels$: Observable<Array<SelectOption>>;
	@Select(state => state.prefillData.filteredTimekeepers) filteredTimekeepers$: Observable<Array<SelectOption>>;

	constructor(private store: Store, private dropdownOptionsService: DropdownOptionsService, private router: Router) { 	
	}

	ngOnInit(): void {
		this.useFilters.forEach(filter => {
			this.activeFilters.items[filter] = "";
			this.selectedFilters.add(filter);
		});

		if (this.selectedFilters.has(availableFilters.status)) {
			this.Statuses = this.dropdownOptionsService.getStatuses();
		}

		if (this.selectedFilters.has(availableFilters.laborOrderState)) {
			this.LaborOrderStates = this.dropdownOptionsService.getLaborOrderStates();
		}


		if (this.selectedFilters.has(availableFilters.timeSheetStatus)) {
			const userRole = this.store.selectSnapshot(UsersState.auth).userRole[0];
			this.timeSheetStates = this.dropdownOptionsService.getTimesheetStatus(userRole);
		}
	}

	ngAfterViewInit(): void {
		this.search.valueChanges
			.pipe(
				debounceTime(500),
				takeUntil(this.unsubscribe$)
			).subscribe(value => {
				this.handleFiltersChange(value, "searchQ");
			});

		this.workedForm.valueChanges
		.pipe(takeUntil(this.unsubscribe$))
		.subscribe(value => {
			this.handleFiltersChange(value, "Worked");
		});

		this.startTime.valueChanges
		.pipe(takeUntil(this.unsubscribe$))
		.subscribe(value => {
			this.handleFiltersChange(value, "StartTime");
		});
	}

	reset() {
		this.activeFilters.hasEnabledFilters = false;
		this.resetValues();
	}

	handleResetButton() {
		this.reset();
		this.store.dispatch(new FilterAction());
		this.filterAction.emit(this.activeFilters);
	}

	resetValues() {
		this.search.setValue("");
		this.useFilters.forEach(filter => {
			this.activeFilters.items[filter] = "";
		});
		this.activeFilters.items["searchQ"] = "";
		this.activeFilters.items["startTime"] = "";
		this.startTime.setValue('');

		this.dropdowns.toArray().forEach(dropdown => {
			dropdown.fullReset();
		});
		this.datePickers.toArray().forEach(datePicker => {
			datePicker.fullReset();
		});
	}

	handleFiltersChange(val, type) {

		const isAlreadySelected = this.activeFilters.items[type] === val;

		//Only emit event if the value is changed
		if (!isAlreadySelected) {
			this.store.dispatch(new FilterAction());

			if (type === "FromDate" || type === "ToDate" || type === "StartDate") {
				this.activeFilters.items[type] = val.toJSON()?.replace("Z", "");
			} else {
				this.activeFilters.items[type] = val;
			}
			
			if(this.router.url == '/orders' && type == availableFilters.startDate) {
				this.activeFilters.items[type] = this.activeFilters.items[type].split("T")[0];
			}

			

			this.activeFilters.hasEnabledFilters = objectHasValues(this.activeFilters.items);

			this.filterAction.emit(this.activeFilters);
		}
	}

	ngOnDestroy() {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}
}
