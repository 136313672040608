import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Vessel } from "src/app/features/admin/vessels/models/vessels.model";
import { LaborOrderState } from "src/app/shared/enums/labor-order-state.enum";
import { API_URL } from "../../../environments/settings";
import { Permission } from "../../core/enums/permission.enum";
import { EnvironmentService } from "../../core/services/environment.service";
import { TimeSheetStatus } from "../../features/timesheets/enums/time-sheet-status.enum";
import { SelectOption } from "../interfaces/select-option.interface";

@Injectable({
  providedIn: "root"
})
export class DropdownOptionsService {
  
  private apiUrl: string;

  constructor(
    private http: HttpClient,
    private env: EnvironmentService
  ) {
    this.apiUrl = this.env.getValueTransferState(API_URL);
  }
  
  getPrefillData() {
    return this.http.post<any>(`${this.apiUrl}/app/prefillProvider/getPrefillData`, {
      includeRequests: [
        {
          entityName: "State",
          keyAttributeName: "Id",
          valueAttributeName: "StateName",
          includeAs: "states"
        },
        {
          entityName: "FacilityType",
          keyAttributeName: "Id",
          valueAttributeName: "Name",
          includeAs: "facilities"
        },
        {
          entityName: "OccCode",
          keyAttributeName: "Id",
          valueAttributeName: "Name",
          includeAs: "occCodes"
        },
        {
          entityName: "CargoDifferential",
          keyAttributeName: "Id",
          valueAttributeName: "Name",
          includeAs: "cargoDifferentials"
        },
        {
          entityName: "MasterCargoType",
          keyAttributeName: "Id",
          valueAttributeName: "Type",
          includeAs: "masterCargoTypes"
        }
      ]
    }).pipe(
      map(res => res.prefillData),
      map((data: any) => {
        return data.map(d => {
          const items = d.filterData.map(item => {
            return { text: item.value, value: item.key };
          });
          return { name: d.filterName, items };
        });
      })
    );
  }

  getStatuses(): Array<SelectOption> {
    return [
      { text: "Active", value: true },
      { text: "Inactive", value: false }
    ];
  }

  getJobTypes() {
    return this.http.post<any>(`${this.apiUrl}/app/prefillProvider/getPrefillData`, {
      includeRequests: [
        {
          entityName: "JobType",
          keyAttributeName: "Id",
          valueAttributeName: "Name",
          includeAs: "jobTypes"
        }
      ]
    })
    .pipe(
      map(res => res.prefillData),
      map((data: any) => {
        return data.map(d => {
          const items = d.filterData.map(item => {
            return { text: item.value, value: item.key };
          });
          return { name: d.filterName, items };
        });
      })
    );
  }

  getLaborOrderStates(): Array<SelectOption> {
    return [
      { text: "Saved", value: LaborOrderState.Saved },
      { text: "Ordered", value: LaborOrderState.Ordered },
      { text: "Rejected", value: LaborOrderState.Rejected },
      { text: "Filled", value: LaborOrderState.Filled }
    ];
  }

  getTimesheetStatus(role: Permission): Array<SelectOption> {
    let statusForRole: Array<TimesheetStatus> = [...TIMESHEET_STATUS];

    if (role) {
      statusForRole = statusForRole.filter((s) => {
        return s.roles.includes(role);
      });
    }

    return (statusForRole.length ? statusForRole : [...TIMESHEET_STATUS]).map((s) => {
      return {
        text: s.text,
        value: s.value
      }
    });
  }

  getVesels(): Observable<Vessel[]> {
    return this.http.get<Vessel[]>(`${this.apiUrl}/app/vessel/asOptionList`);
  }

}

interface TimesheetStatus {
  text: string;
  value: TimeSheetStatus;
  roles: Array<Permission>;
}

export const TIMESHEET_STATUS: Array<TimesheetStatus> = [
  {
    text: "Approved",
    value: TimeSheetStatus.Approved,
    roles: [Permission.SingleStepApprover, Permission.EmployerTimekeeper, Permission.EmployerSupervisor]
  },
  {
    text: "Draft",
    value: TimeSheetStatus.Draft,
    roles: [Permission.UnionTimekeeper, Permission.EmployerSupervisor, Permission.EmployerTimekeeper]
  },
  {
    text: "Exported",
    value: TimeSheetStatus.Exported,
    roles: [Permission.SingleStepApprover, Permission.EmployerTimekeeper, Permission.EmployerSupervisor]
  },
  {
    text: "Pending Approval",
    value: TimeSheetStatus.PendingApproval,
    roles: [Permission.EmployerTimekeeper, Permission.EmployerSupervisor]
  },
  {
    text: "Pending Reapproval",
    value: TimeSheetStatus.PendingReapproval,
    roles: [Permission.EmployerTimekeeper, Permission.EmployerSupervisor]
  },
  {
    text: "Rejected",
    value: TimeSheetStatus.Rejected,
    roles: [Permission.UnionTimekeeper, Permission.EmployerTimekeeper, Permission.EmployerSupervisor]
  },
  {
    text: "Under Review",
    value: TimeSheetStatus.UnderReview,
    roles: [Permission.EmployerTimekeeper, Permission.EmployerSupervisor]
  }
]